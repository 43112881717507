import React from "react"
import { Link } from "gatsby"
import '../styles/utilitary.css'
import '../styles/posta.css'
import Layout from "../components/layout"
import SEO from "../components/seo"
import logo from "../images/logo.png" // Tell webpack this JS file uses this image
import postascover from "../images/PostadeSalmaoemCamadeLegumes.jpg" // Tell webpack this JS file uses this image
import bacicones from "../images/bac-icones.png" // Tell webpack this JS file uses this image
import bacicones2 from "../images/bac-icones2.png" // Tell webpack this JS file uses this image
import logonew from "../images/logo-new-salmon.png" // Tell webpack this JS file uses this image
import ingrediente from "../images/ingrediente.svg" // Tell webpack this JS file uses this image
import SalmaodeCebolada from "../images/SalmaodeCebolada.mp4"

const postaSalmao = () => (
<Layout>
   <SEO title="Peixe Fácil - Posta de Salmão" />
   <div className="wrapper-bg-posta">
      <div className="center-col-bg">
         <div className="logo-brand">
            <img src={logonew} alt="" />
         </div>
      </div>
   </div>
   <div id="section-1-posta">
      <div className="wrapper-container">
         {/* <div className="mobile-logo">
            <img src={logonew} alt="Logo" />
         </div> */}
         <div className="titulo absara blue-marine">
            <p>Posta de Salmão</p>
         </div>
         <div className="descricao sneak-regular opacity">
            <p> Tradicional e nunca desilude. É assim, a posta do nosso salmão. </p>
         </div>
      </div>
   </div>
   <div id="recipes-section-posta">
      <div className="wrapper-section">
        <div className="col-1">
         <video className="video-class" preload='auto' controls autoPlay loop muted playsInline>
            <source src={SalmaodeCebolada} type="video/mp4" />
         </video>
        </div>
        <div className="col-2">
        <div className="wrapper">
           <div className="card-recipe">
              
              <div className="top">
              <p className="title absara"> 
                 Posta de salmão e cebolada
                 </p>
              </div>
              <div className="bottom">
                  <div className="left-col">
                     <div className="card-ingedientes">
                        <span className="titulo absara">Ingredientes</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">4 Postas de Salmão</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">4 cebolas</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 dente de alho</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 folha de louro</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">20cl de vinho branco</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">10cl de azeite virgem</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Sal e pimenta Q/B</span>
                     </div>
                  </div>
                  <div className="right-col">
                       <div className="card-receita">
                       <span className="titulo absara">Receita</span><br></br>
                       <span className="span-icon">1 -</span><span className="span-text">Corte as cebolas em rodelas e coloque a refogar com azeite, um dente de alho esmagado e uma folha de louro</span><br></br>
                       <span className="span-icon">2 -</span><span className="span-text">Adicione o vinho branco, tempere com sal e pimenta e deixe em lime médio até evaporação total do vinho</span><br></br>
                       <span className="span-icon">3 -</span><span className="span-text">Grelhe as Postas de Salmão</span><br></br>
                       <span className="span-icon">4 -</span><span className="span-text">Coloque o peixe num prato à sua escolha e disponha a cebolada por cima</span><br></br>
                       <span className="span-icon">5 -</span><span className="span-text">Acompanhe com batatas ou legumes</span><br></br>


                     </div>
                     </div>
                 </div>

                 <div className="section-nutri">
                  <div className="caracts">
            
            <div className="wrapper">
           
                <div className="card-caracts">
                  
                <div className="main-titulo absara">
                   Valores Nutricionais
                </div>
                <div className="contentor">
                <div className="col-1-nutri">
                        <div className="titulo absara">
                            Energia Kcal
                        </div>
                        <div className="titulo absara">
                            Lipidos g
                        </div>
                        <div className="titulo absara">
                            Saturados g
                        </div>
                        <div className="titulo absara">
                            Hidratos g
                        </div>
                        <div className="titulo absara">
                            Açucares g
                        </div>
                        <div className="titulo absara">
                            Proteinas g
                        </div>
                        <div className="titulo absara">
                            Fibra g
                        </div>
                     
                    </div>
                    <div className="col-2-nutri">
                    <div className="titulo sneak-regular">
                            626
                        </div>
                        <div className="titulo sneak-regular">
                            56
                        </div>
                        <div className="titulo sneak-regular">
                          10
                        </div>
                        <div className="titulo sneak-regular">
                      5
                        </div>
                        <div className="titulo sneak-regular">
                         0
                        </div>
                        <div className="titulo sneak-regular">
                         26
                        </div>
                        <div className="titulo sneak-regular">
                         2
                        </div>
                       
                        </div>
                </div>
                   
                  
                    
                    
                </div>
            </div>
        </div>
    </div>
           </div>
        </div>
        </div>
      </div>

      <div className="wrapper-section-2">
      <div className="col-1-mobile">

    </div>
        <div className="col-1">
        <div className="wrapper">
           <div className="card-recipe">
              
              <div className="top">
              <p className="title absara"> 
                 Posta de salmão com risotto de lima
                 </p>
              </div>
              <div className="bottom">
                  <div className="left-col">
                     <div className="card-ingedientes">
                        <span className="titulo absara">Ingredientes</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">4 Postas de Salmão</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">200g de arroz arbório</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">2 Limas</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">25cl de vinho branco</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">2 Chalotas</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">20g de manteiga</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">400cl de água</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">5cl de azeite</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Ramo de cebolinho</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Sal Q/B</span><br></br>                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Sal Q/B</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Pimenta Q/B</span><br></br>



                     </div>
                  </div>
                  <div className="right-col">
                       <div className="card-receita">
                       <span className="titulo absara">Receita</span><br></br>
                       <span className="span-icon">1 -</span><span className="span-text">Pique as chalotas, refogue em azeite até lourar, junte o arroz  tempere</span><br></br>
                       <span className="span-icon">2 -</span><span className="span-text">Adicione o vinho branco e deixe reduzir a metade</span><br></br>
                       <span className="span-icon">3 -</span><span className="span-text">Coloque a água e deixe cozinhar mexendo frequentemente com uma colher até abosrção do líquido</span><br></br>
                       <span className="span-icon">4 -</span><span className="span-text">Finalize adicionando a manteiga e zestes de lima</span><br></br>
                       <span className="span-icon">5 -</span><span className="span-text">Sirva com as postas de salmão salteadas com azeite e cebolinho</span><br></br>


                     </div>
                     </div>
                 </div>

                 <div className="section-nutri">
                  <div className="caracts">
            
            <div className="wrapper">
           
                <div className="card-caracts">
                  
                <div className="main-titulo absara">
                   Valores Nutricionais
                </div>
                <div className="contentor">
                <div className="col-1-nutri">
                        <div className="titulo absara">
                            Energia Kcal
                        </div>
                        <div className="titulo absara">
                            Lipidos g
                        </div>
                        <div className="titulo absara">
                            Saturados g
                        </div>
                        <div className="titulo absara">
                            Hidratos g
                        </div>
                        <div className="titulo absara">
                            Açucares g
                        </div>
                        <div className="titulo absara">
                            Proteinas g
                        </div>
                        <div className="titulo absara">
                            Fibra g
                        </div>
                     
                    </div>
                    <div className="col-2-nutri">
                    <div className="titulo sneak-regular">
                            838
                        </div>
                        <div className="titulo sneak-regular">
                            42
                        </div>
                        <div className="titulo sneak-regular">
                          11
                        </div>
                        <div className="titulo sneak-regular">
                      82
                        </div>
                        <div className="titulo sneak-regular">
                         4
                        </div>
                        <div className="titulo sneak-regular">
                         33
                        </div>
                        <div className="titulo sneak-regular">
                        7
                        </div>
                       
                        </div>
                </div>
                   
                  
                    
                    
                </div>
            </div>
        </div>
    </div>
           </div>
        </div>
        </div>
        <div className="col-2">

</div>
      </div>

      <div className="wrapper-section-3">
      <div className="col-1-mobile">

</div>
<div className="col-1">
 
 </div>
        <div className="col-2">
        <div className="wrapper">
           <div className="card-recipe">
              
              <div className="top">
              <p className="title absara"> 
                 Posta de Salmão em cama de legumes
                 </p>
              </div>
              <div className="bottom">
                  <div className="left-col">
                     <div className="card-ingedientes">
                     <span className="titulo absara">Ingredientes</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">4 Postas de Salmão</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Curgete</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">2 Cenouras</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Alho francês</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Cebola roxa</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Pimento amarelo</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Ramo de salsa</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">10cl de azeite</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">5cl de vinagre balsâmico</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Flor de sal Q/B</span><br></br>


                     </div>
                  </div>
                  <div className="right-col">
                       <div className="card-receita">
                       <span className="titulo absara">Receita</span><br></br>
                       <span className="span-icon">1 -</span><span className="span-text">Corte os legumes em juliana previamente descascados e limpos</span><br></br>
                       <span className="span-icon">2 -</span><span className="span-text">Coza as cenouras e a courgete em água abundante com sal até ficarem crocantes e escorra colocando de imediato a arrefecer em água e gelo</span><br></br>
                       <span className="span-icon">3 -</span><span className="span-text">Salteie numa frigideira com os restantes legumes, o azeite e adicione o vinagre balsâmico e a salsa picada</span><br></br>
                       <span className="span-icon">4 -</span><span className="span-text">Disponha fatias de abacate num prato e coloque a marinada por cima juntamente com o salmão.</span><br></br>
                       <span className="span-icon">5 -</span><span className="span-text">Tempere com flor de sal</span><br></br>
                       <span className="span-icon">6 -</span><span className="span-text">Sirva com as postas de salmão</span><br></br>



                     </div>
                     </div>
                 </div>

                 <div className="section-nutri">
                  <div className="caracts">
            
            <div className="wrapper">
           
                <div className="card-caracts">
                  
                <div className="main-titulo absara">
                   Valores Nutricionais
                </div>
                <div className="contentor">
                <div className="col-1-nutri">
                        <div className="titulo absara">
                            Energia Kcal
                        </div>
                        <div className="titulo absara">
                            Lipidos g
                        </div>
                        <div className="titulo absara">
                            Saturados g
                        </div>
                        <div className="titulo absara">
                            Hidratos g
                        </div>
                        <div className="titulo absara">
                            Açucares g
                        </div>
                        <div className="titulo absara">
                            Proteinas g
                        </div>
                        <div className="titulo absara">
                            Fibra g
                        </div>
                     
                    </div>
                    <div className="col-2-nutri">
                    <div className="titulo sneak-regular">
                            482
                        </div>
                        <div className="titulo sneak-regular">
                            34
                        </div>
                        <div className="titulo sneak-regular">
                          7
                        </div>
                        <div className="titulo sneak-regular">
                      13
                        </div>
                        <div className="titulo sneak-regular">
                         10
                        </div>
                        <div className="titulo sneak-regular">
                         31
                        </div>
                        <div className="titulo sneak-regular">
                        8
                        </div>
                       
                        </div>
                </div>
                   
                  
                    
                    
                </div>
            </div>
        </div>
    </div>
           </div>
        </div>
        </div>
      
      </div>

   </div>
   <div className="wrapper-3-posta">
      <div className="logo-footer">
      <div className="logo-brand">
            <img src={logonew} alt="" />
         </div>

      </div>
      <div className="btn-div">
         <Link to="/" style={{ textDecoration: 'none' }}> <button className="btn sneak-bold">Descubra outros produtos!</button></Link>
      </div>
   </div>


</Layout>
)
export default postaSalmao